<template>
  <div class="input__w">
    <div class="input">
      <input
        ref="input"
        v-model.trim="getValue"
        class="input__field"
        :type="isPasswordHide ? 'password' : 'text'"
        :autocomplete="autocompleteType"
        :class="[
          { filled: input.value },
          { error: (validate.$error && validate.$dirty) || customError },
        ]"
        @blur="onBlur"
      />
      <span class="input__caption">{{ caption }}</span>
      <div class="input__show-password" @click="toggleShowPassword">
        <img
          v-show="isPasswordHide"
          src="~~/assets/icons/eye-off.svg"
          alt="show password"
        />
        <img
          v-show="!isPasswordHide"
          src="~~/assets/icons/eye-on.svg"
          alt="hide password"
        />
      </div>
      <div
        v-show="(validate.$error && validate.$dirty) || customError"
        class="input__error-img"
      >
        <img src="~~/assets/icons/error-input.svg" alt="error attention" />
      </div>
    </div>
    <div class="input__error-caption-w">
      <p v-if="null !== customError" class="input__error-caption">
        {{ customError }}
      </p>
      <p
        v-else-if="validate.$error && validate.$dirty"
        class="input__error-caption"
      >
        {{ validate.$errors[0].$message }}
      </p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  caption: { type: String, required: false },
  modelValue: { type: String, required: false },
  validate: { type: Object, required: true },
  customError: { required: false, default: null },
  autocompleteType: { type: String, required: false },
});

const emits = defineEmits(["update:modelValue"]);

const input = ref("");
const isPasswordHide = ref(true);

const getValue = computed({
  get: () => props.modelValue,
  set: (value) => emits("update:modelValue", value),
});

function onBlur() {
  props.validate.$touch();
}

function toggleShowPassword() {
  isPasswordHide.value = !isPasswordHide.value;
}
</script>
