<template>
  <ClientOnly>
    <div
      v-if="null === userStore.currentUser"
      class="item-account"
      @click="modalStore.toggleModal(accountModal, { component: 'signIn' })"
    >
      <div class="item-account__image">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="5" y="5" width="30" height="30" rx="15" fill="#602C88"/>
          <path d="M27.7274 28.4471C27.2716 27.1713 26.2672 26.0439 24.8701 25.2399C23.4729 24.4358 21.7611 24 20 24C18.2389 24 16.5271 24.4358 15.1299 25.2399C13.7328 26.0439 12.7284 27.1713 12.2726 28.4471" stroke="#FAC748" stroke-width="1.5" stroke-linecap="round"/>
          <circle cx="20" cy="16" r="4" stroke="#FAC748" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
      </div>
    </div>
    <div v-else class="item-account">
      <MyLink
        class="item-account__item-link"
        name="account_profile"
      >
        <div class="item-account__image account">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.7274 28.4471C27.2716 27.1713 26.2672 26.0439 24.8701 25.2399C23.4729 24.4358 21.7611 24 20 24C18.2389 24 16.5271 24.4358 15.1299 25.2399C13.7328 26.0439 12.7284 27.1713 12.2726 28.4471" stroke="#1F1F1F" stroke-width="1.6" stroke-linecap="round"/>
            <circle cx="20" cy="16" r="4" stroke="#1F1F1F" stroke-width="1.6" stroke-linecap="round"/>
          </svg>
        </div>
      </MyLink>
    </div>
  </ClientOnly>
</template>

<script setup>
import { useCartStore } from "~~/store/cartStore";
import { useLanguageFilterStore } from "~~/store/languageFilterStore";
import { useComparisonStore } from "~~/store/comparisonStore";
import { accountModal, useModalStore } from "~/modules/shared/modals/store/modalStore";
import { useUserStore } from "~/store/userStore";

const comparisonStore = useComparisonStore();

const cartStore = useCartStore();

const modalStore = useModalStore();
const userStore = useUserStore();

onBeforeMount(async () => {
  const promises = [
    new Promise((resolve) => resolve(cartStore.fetch())),
    new Promise((resolve) => resolve(comparisonStore.fetch())),
  ];

  await Promise.all(promises);
});
</script>

<style lang="scss" scoped>
.item-account {
  @include flex-container(column, center, center);

  @include fixedHW(40px);

  cursor: pointer;

  &:hover {
    svg {
      path,
      circle {
        stroke: var(--color-primary-lightest);
      }
    }
}

  @include bigMobile {
    display: none;
  }

  &__item-link {
    @include flex-container(column, center, center);
  }

  &__image {
    position: relative;

    &.account {
      @include fixedHW(40px);

      @include flex-container(row, center, center);

      background-color: transparent;
      border-radius: 50%;

      &:hover {
        svg {
          path,
          circle {
            stroke: var(--color-ink-base);
          }
        }
      }
    }

    & > svg {
      @include fixedHW(40px);
      
      @include bigMobile {
        @include fixedHW(28px);
      }

      path,
      circle {
        transition: stroke .1s ease-in-out;
      }
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>