<template>
  <BaseModal
    :title="
      product.status === 'preOrder'
        ? _T('@Product pre-order')
        : _T('@Report availability')
    "
    @on-close-modal="onCloseModal"
  >
    <div class="modal-preorder__body">
      <div class="modal-preorder__select-item">
        <div class="modal-preorder__img">
          <img
            :src="product.getMainImage('small')"
            :alt="cleanStringsConcat([product.getName(), 'image'])"
          />
        </div>
        <div class="modal-preorder__item-info">
          <p class="modal-preorder__item-name">
            {{ product.getName() }}
          </p>
          <p class="modal-preorder__item-code">
            {{ `${_T("@Code")} ${product.code}` }}
          </p>
        </div>
      </div>
      <form action="" class="modal-preorder__form">
        <InputText
          v-model="form.firstName"
          :validate="v.firstName"
          :caption="_T('@Name')"
          :maxlength="255"
          autocomplete-type="given-name"
        />
        <InputPhone
          v-model="form.phone"
          :validate="v.phone"
          :caption="_T('@Phone number')"
        />
        <InputEmail v-model="form.email" :validate="v.email" caption="E-mail" />
        <div class="modal-preorder__checkbox-wrp">
          <BaseCheckbox
            v-model="form.isAgreementAccepted"
            :validate="v.isAgreementAccepted"
            color="var(--color-blue-700)"
          >
            <span>
              {{ _T("@I agree with") }}
              <MyLink name="delivery" target="_blank">
                {{ _T("@terms of delivery") }}
              </MyLink>
              {{ _T("@and") }}
              <MyLink name="privacy-policy" target="_blank">
                {{ _T("@privacy policy") }}
              </MyLink>
            </span>
          </BaseCheckbox>
        </div>
        <ButtonProductSubscribe
          :status="product.status"
          :disable="sendForm.isHandled"
          @click.prevent="onSubmit"
        />
      </form>
    </div>
  </BaseModal>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import InputEmail from "~/modules/shared/inputs/InputEmail.vue";
import InputText from "~~/modules/shared/inputs/InputText.vue";
import InputPhone from "~~/modules/shared/inputs/InputPhone.vue";
import ButtonProductSubscribe from "~/modules/shared/buttons/ButtonProductSubscribe.vue";
import { formatPhone } from "~~/utils/phoneHelper";
import {
  preOrderModal,
  successfulModal,
  useModalStore,
} from "~~/modules/shared/modals/store/modalStore";
import {
  emailValidate,
  maxLengthValidate,
  phoneValidate,
  regExpValidate,
  requiredValidate,
  sameAsValidate,
} from "~/utils/validators";
import { modalFormStore } from "~/modules/shared/modals/store/modalFormStore";
import { useUserStore } from "~/store/userStore";
import { useSingletonHandler } from "~/uses/useSingletonHandler";
import { useAPI } from "~/uses/useMyFetch";
import BaseModal from "~/modules/shared/modals/BaseModal.vue";
import BaseCheckbox from "~/modules/shared/inputs/BaseCheckbox.vue";

const modalStore = useModalStore();
const product = storeToRefs(modalStore).getExtra.value(preOrderModal);

const formStore = modalFormStore();

const userStore = useUserStore();
const { currentUser: user } = storeToRefs(userStore);

onMounted(() => {
  if (null !== user.value) {
    form.firstName = user.value.name.firstName;
    form.phone = user.value.phone;
    form.email = user.value.email;
  }

  formStore.addForm({ v, id: "preorderSubscription" });
});

const successfulData = {
  title: _T("@Successful modal title"),
  description: _T("@Successful modal text"),
  isWithTimeout: true,
};

const form = reactive({
  firstName: "",
  phone: "",
  email: "",
  isAgreementAccepted: true,
});

const rules = computed(() => ({
  firstName: {
    required: requiredValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 255),
    validateName: regExpValidate("@Must not contain spaces", /^\S*$/),
  },
  phone: {
    required: requiredValidate(),
    phone: phoneValidate(),
  },
  email: {
    required: requiredValidate(),
    email: emailValidate(),
  },
  isAgreementAccepted: {
    sameAs: sameAsValidate("@You must accept the terms and conditions", true),
  },
}));

const v = useVuelidate(rules, form);

const sendForm = useSingletonHandler(() =>
  useAPI("/catalog/product/subscription/add", {
    method: "POST",
    body: {
      productId: product.id,
      type: product.status === "preOrder" ? "preOrder" : "order",
      email: form.email,
      phone: formatPhone(form.phone),
      name: form.firstName,
    },
  }).then(() => {
    modalStore.toggleModal(preOrderModal);

    modalStore.toggleModal(successfulModal, successfulData);
  }),
);

const onSubmit = async () => {
  if (!formStore.isFormsReady()) {
    return;
  }

  return await sendForm.handle();
};

const onCloseModal = () => {
  modalStore.toggleModal(preOrderModal);
};

onUnmounted(() => {
  formStore.resetForms();
});
</script>

<style lang="scss" scoped>
.modal-preorder {
  &__body {
    @include flex-container(column, space-between);

    gap: 24px;
  }

  &__select-item {
    @include flex-container(row, space-between, center);

    gap: 16px;
  }

  &__img {
    width: 90px;
    height: 90px;

    flex: 0 0 auto;
  }

  &__item-info {
    @include flex-container(column, space-between);

    gap: 8px;
  }

  &__item-name {
    @include font(16, 22, 600);
    letter-spacing: 0.02em;
    color: #2b2b2b;
  }

  &__item-code {
    @include font(12, 16);
    letter-spacing: 0.02em;
    color: #2b2b2b;
  }

  &__form {
    @include flex-container(column, space-between, center);

    gap: 24px;
  }

  &__checkbox-wrp {
    max-width: 363px;
  }
}
</style>
