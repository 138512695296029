<template>
  <div class="cart-footer">
    <ButtonSecondary
      class="cart-footer__continue"
      button-width="max-content"
      @click.prevent="closeCart"
    >
      {{ _T("@Continue Shopping") }}
    </ButtonSecondary>
    <div class="cart-footer__receipt">
      <div class="cart-footer__receipt-sum">
        <span class="cart-footer__receipt-sum-label">
          {{ _T("@Total") }}:
        </span>
        <span class="cart-footer__receipt-sum-price">
          {{ priceFormatter(amount) }} &#8372;
        </span>
      </div>
      <MyLink class="button-primary" name="checkout" @click.prevent="closeCart">
        {{ _T("@Place an Order") }}
      </MyLink>
    </div>
  </div>
</template>

<script setup>
import { priceFormatter } from "~/utils/formatters.utils";
import ButtonSecondary from "~/modules/shared/buttons/ButtonSecondary.vue";
import { useCartStore } from "~/store/cartStore";

const props = defineProps({
  closeCart: { type: Function, required: true },
});

const cartStore = useCartStore();
const { getTotalAmount: amount } = storeToRefs(cartStore);
</script>

<style scoped lang="scss">
.cart-footer {
  position: sticky;
  z-index: 500;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;

  @include flex-container(row, space-between, flex-end);
  gap: 16px;

  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;

  background-color: white;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);

  padding: 16px;

  @include mobile {
    padding: 8px;
    margin-top: auto;
  }

  &__continue {
    @include mobile {
      display: none;
    }
  }

  &__receipt {
    @include flex-container(row, flex-end, flex-end);
    flex-wrap: wrap;
    gap: 16px;

    @include mobile {
      width: 100%;

      flex-direction: column;
    }
  }

  &__receipt-sum {
    @include flex-container;
    gap: 16px;

    @include mobile {
      width: 100%;
    }
  }

  &__receipt-sum-label {
    @include font(20, 28);
    letter-spacing: 0.02em;

    @include mobile {
      @include font;
    }
  }

  &__receipt-sum-price {
    @include font(20, 30, 700);

    @include mobile {
      @include font(16, 22, 700);
    }
  }
}

.button-primary {
  width: max-content;
  white-space: nowrap;

  @include mobile {
    width: 100%;
  }
}
</style>
