<template>
  <div class="cart-related-card">
    <ProductLink
      :product="product"
      :class="'cart-related-product-link-' + product.id"
      @click.prevent="onNavigateToProduct"
    >
      <img
        class="cart-related-card__image"
        :src="product.getMainImage('medium')"
        :alt="product.getName()"
      />
    </ProductLink>
    <div class="cart-related-card__info">
      <ProductLink
        :product="product"
        :class="'cart-related-product-link-' + product.id"
        @click.prevent="onNavigateToProduct"
      >
        <p class="cart-related-card__title">
          {{ product.getName() }}
        </p>
      </ProductLink>
      <div class="cart-related-card__footer">
        <ProductPrice :product="product" :price-size="16" />
        <ButtonBuy
          :class="'cart-related-product-buy-' + product.id"
          :product="product"
          :minified-view="true"
          :minified-size="32"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { Product } from "~/models/product.model";
import ProductPrice from "~/modules/shared/price/ProductPrice.vue";
import ButtonBuy from "~/modules/shared/buttons/ButtonBuy.vue";
import {
  cartModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";

const props = defineProps({
  product: { type: Product, required: true },
});

const modalStore = useModalStore();

const onNavigateToProduct = () => {
  modalStore.toggleModal(cartModal);
};
</script>

<style scoped lang="scss">
.cart-related-card {
  @include flex-container();
  gap: 8px;

  border: 1px solid var(--color-sky-light);
  border-radius: 16px;

  padding: 8px;

  &__image {
    @include fixedHW(90px);
  }

  &__info {
    @include flex-container(column, space-between);
    gap: 16px;
  }

  &__title {
    @include font(14, 16);

    @include lineClamp(2);

    &:hover {
      color: var(--color-primary-base);
      text-decoration: underline;
    }
  }

  &__footer {
    @include flex-container(row, space-between, center);
    gap: 4px;
  }
}
</style>
