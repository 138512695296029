<template>
  <BaseModal :title="_T('@Order')" @on-close-modal="onCloseModal">
    <div class="modal-spp">
      <p class="modal-spp__text">
        {{ _T("@The manager will contact you to clarify") }}
      </p>
      <form action="" class="modal-spp__form">
        <InputText
          v-model="form.name"
          :validate="v.name"
          :maxlength="255"
          :caption="_T('@Name')"
          :autocomplete-type="'given-name'"
        />
        <InputPhone
          v-model="form.phone"
          :validate="v.phone"
          :caption="_T('@Phone number')"
        />
        <InputText
          v-model="form.message"
          :validate="v.message"
          :maxlength="1024"
          :caption="_T('@Message')"
        />
        <ButtonPrimary
          type="submit"
          :aria-label="_T('@Order')"
          button-width="363px"
          @click.prevent="subscribe"
        >
          {{ _T("@Order") }}
        </ButtonPrimary>
        <p class="modal-spp__text">
          {{ _T("@Your data will not be passed on to third parties") }}
        </p>
      </form>
    </div>
  </BaseModal>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import InputText from "~~/modules/shared/inputs/InputText.vue";
import InputPhone from "~~/modules/shared/inputs/InputPhone.vue";
import {
  successfulModal,
  useModalStore,
  sppModal,
} from "~/modules/shared/modals/store/modalStore";
import {
  maxLengthValidate,
  phoneValidate,
  regExpValidate,
  requiredValidate,
} from "~/utils/validators";
import { formatPhone } from "~~/utils/phoneHelper";
import { modalFormStore } from "~/modules/shared/modals/store/modalFormStore";
import { useUserStore } from "~/store/userStore";
import { useAPI } from "~/uses/useMyFetch";
import BaseModal from "~/modules/shared/modals/BaseModal.vue";
import ButtonPrimary from "~/modules/shared/buttons/ButtonPrimary.vue";

const modalStore = useModalStore();
const formStore = modalFormStore();

const userStore = useUserStore();
const { currentUser: user } = storeToRefs(userStore);

const successfulData = {
  title: _T("@Successful modal title"),
  description: _T("@Successful modal text"),
  isWithTimeout: true,
};

const form = reactive({
  name: "",
  phone: "",
  message: "",
});

const rules = computed(() => ({
  name: {
    required: requiredValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 255),
    validateName: regExpValidate("@Must not contain spaces", /^\S*$/),
  },
  phone: {
    required: requiredValidate(),
    phone: phoneValidate(),
  },
  message: {
    maxLength: maxLengthValidate("@Maximum field length", 1024),
  },
}));

const v = useVuelidate(rules, form);

function subscribe() {
  if (formStore.isFormsReady()) {
    useAPI("/marketing/event", {
      method: "POST",
      body: {
        event: "solarPowerPlantOrderCreated",
        phone: formatPhone(form.phone),
        isConfirmed: false,
        name: form.name,
        form: {
          message: form.message,
        },
      },
    })
      .then(() => {
        modalStore.toggleModal(sppModal);

        modalStore.toggleModal(successfulModal, successfulData);
      })
      .catch(() => {});
  }
}

const onCloseModal = () => {
  modalStore.toggleModal(sppModal);
};

onMounted(() => {
  if (null !== user.value) {
    form.firstName = user.value.name.firstName;
    form.phone = user.value.phone;
  }

  formStore.addForm({ v, id: "sppSubscribe" });
});

onUnmounted(() => {
  formStore.resetForms();
});
</script>

<style lang="scss" scoped>
.modal-spp {
  @include flex-container(column, space-between);
  gap: 24px;

  &__text {
    @include font(16, 20, 400);
    text-align: center;

    @include mobile {
      @include font(14, 18, 400);
    }
  }

  &__form {
    width: 100%;
    @include flex-container(column, center, center);

    gap: 24px;
  }
}
</style>
