<template>
  <div ref="refModal" class="modal" tabindex="0" @keydown.esc="onCloseModal">
    <div 
      v-on-click-outside="onCloseModal" 
      class="modal__wrapper"
      :class="{
        'sign-up': currentTab === 'signUp',
        'sign-in': currentTab === 'signIn',
        'password-reset': currentTab === 'passwordReset',
      }"
    >
      <div class="modal__content">
        <div
          class="modal__header"
          :class="{ 'no-border': withoutHeaderBorder }"
        >
          <p v-if="title" class="modal__title">{{ title }}</p>
          <ButtonClose
            :color="closeButtonColor"
            class="modal__close"
            @click.prevent="onCloseModal"
          />
        </div>
        <div class="modal__body">
          <slot />
        </div>
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script setup>
import ButtonClose from "~/modules/shared/buttons/ButtonClose.vue";
import { vOnClickOutside } from "@vueuse/components";

const props = defineProps({
  title: { type: String, required: false },
  modalWidth: { type: Number, required: false, default: 500 },
  backgroundColor: { type: String, required: false, default: "white" },
  titleColor: { type: String, required: false, default: "#0e0f0f" },
  closeButtonColor: { type: String, required: false, default: "black" },
  backgroundImage: { type: String, required: false, default: "none" },
  withoutHeaderBorder: { type: Boolean, required: false, default: false },
  currentTab: { type: String, required: false },
});

const emits = defineEmits(["onCloseModal"]);

const refModal = ref(null);

const onCloseModal = () => {
  emits("onCloseModal");
};

onMounted(() => {
  refModal.value.focus();
});
</script>

<style scoped lang="scss">
.modal {
  display: flex;

  position: fixed;
  @include setAbs(0, 0, 0, 0);
  z-index: 510;

  background-color: rgb(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);

  cursor: auto;

  &__wrapper {
    max-width: calc(v-bind(modalWidth) * 1px);
    width: 100%;

    margin: auto;

    position: relative;
    
    &.sign-up {
      max-width: 1044px;

      @include bigMobile {
        max-width: 700px;
        
        background-color: white;
      }
    }

    &.sign-in {
      max-width: 700px;

      @include bigMobile {
        max-width: 500px;
      }
    }
 
    &.password-reset {
      max-width: 360px;

      .modal__body {
        padding: 24px 16px;
      }
    }

    &.sign-up,
    &.sign-in {
      .modal__header {
        width: 100%;

        position: absolute;

        border: none;

        padding: 8px 16px;

        @include bigMobile {
          position: static;

          border-bottom: 1px solid var(--color-sky-base);
          
          padding: 16px;
        }
      }

      .modal__body {
        padding: 0;
      }
    }

    @include mobile {
      height: 100%;
      overflow: auto;
    }
  }

  &__content {
    position: relative;

    background-color: v-bind(backgroundColor);
    background-image: v-bind(backgroundImage);
    background-size: cover;
    border-radius: 8px;

    @include mobile {
      height: 100%;

      display: flex;
      flex-direction: column;

      border-radius: 0;
    }
  }

  &__header {
    @include flex-container(row, space-between, center);

    border-bottom: 1px solid var(--color-primary-base);

    padding: 8px 16px;
    gap: 16px;

    &.no-border {
      border: none;
      border-radius: 8px;
    }
  }

  &__title {
    @include font(20, 24, 500);
    letter-spacing: 0.02em;
    color: v-bind(titleColor);
  }

  &__close {
    grid-column-start: 2;
    justify-self: end;
  }
}
</style>