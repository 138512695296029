<template>
  <section class="widget-helper-mob" :class="{ active: activeMainWidget }">
    <Transition name="widget">
      <div
        v-if="!activeMainWidget"
        class="widget-helper-mob__main"
        @click="openMainWidget"
      >
        <div class="widget-helper-mob__main-item">
          <p class="widget-helper-mob__main-item-text">
            {{ _T("@Connect with us") }}
          </p>
        </div>
      </div>
    </Transition>
    <Transition name="widget-open">
      <div v-if="activeMainWidget" class="widget-helper-mob__close">
        <div class="widget-helper-mob__close-item" @click="closeMainWidget">
          <span />
        </div>
        <div class="widget-helper-mob__close-title">
          {{ _T("@Connect with us") }}
        </div>
        <div class="widget-helper-mob__content">
          <div
            v-for="(item, index) in allWidgets"
            :key="index"
            class="widget-helper-mob__item"
            @click="clickSelectedIcon(item.link)"
          >
            <div class="widget-helper-mob__item-picture">
              <img :src="`/img/social-media/${item.img}.svg`" :alt="item.img" />
            </div>
            <p class="widget-helper-mob__item-text">{{ _T(item.name) }}</p>
          </div>
          <div class="widget-helper-mob__item" @click="clickPhone">
            <div class="widget-helper-mob__item-picture">
              <img
                :src="`/img/social-media/${widgetPhone.img}.svg`"
                :alt="widgetPhone.img"
              />
            </div>
            <p class="widget-helper-mob__item-text">
              {{ _T(widgetPhone.name) }}
            </p>
          </div>
        </div>
      </div>
    </Transition>
  </section>
</template>

<script setup>
const widgetPhone = ref({
  img: "phone",
  name: "@Back call",
});

const allWidgets = ref([
  {
    img: "telegram",
    name: "Telegram",
    link: "https://t.me/LogicPowerBot",
  },
  {
    img: "viber",
    name: "Viber",
    link: "viber://pa?chatURI=lpofficial",
  },
  {
    img: "help-center",
    name: "@Help desk widget",
    link: "https://t.me/LogicPowerSupport_bot",
  },
]);

const activeMainWidget = ref(false);
function openMainWidget() {
  activeMainWidget.value = !activeMainWidget.value;
  document.documentElement.style.overflow = "hidden";
}

function closeMainWidget() {
  activeMainWidget.value = !activeMainWidget.value;
  document.documentElement.style.overflow = "auto";
}

function clickSelectedIcon(url) {
  window.open(url, "_blank");
  closeMainWidget();
}

function clickPhone() {
  ringostatAPI.openCallbackForm();
  closeMainWidget();
}
</script>

<style lang="scss" scoped>
.widget-helper-mob {
  width: 64px;
  height: 64px;

  position: fixed;
  z-index: 1000;
  bottom: 160px;
  right: 16px;

  transition: all 0.5s ease-in-out;

  &.active {
    width: 100%;
    height: 100%;

    position: fixed;
    z-index: 1500;
    bottom: 0;
    right: 0;

    background-color: white;
  }

  &__main {
    width: 64px;
    height: 64px;

    position: absolute;
    z-index: 4;

    background-color: var(--color-primary-dark);
    border-radius: 50%;

    cursor: pointer;
    overflow: hidden;
  }

  &__main-item {
    width: 64px;
    height: 64px;

    @include flex-container(column, center, center);

    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;

    transition: all 0.4s ease-in-out;
  }

  &__main-item-text {
    @include font(9, 10, 600);
    text-align: center;
    color: white;

    padding: 2px 1px;
  }

  &__close {
    width: 100%;
    height: 100%;

    @include flex-container(column, flex-start);

    padding: 40px 16px;
    gap: 16px;
  }

  &__close-item {
    width: 24px;
    height: 24px;

    position: absolute;
    top: 40px;
    right: 16px;

    transition: all 0.4s ease-in-out;
    cursor: pointer;

    & span {
      position: absolute;
      z-index: 4;
      top: 10px;
      left: 2px;

      width: 20px;
      height: 2px;
      background-color: black;
      transform: rotate(45deg);
      transition: all 0.4s ease-in-out;

      &::after {
        content: "";
        position: absolute;
        z-index: 4;
        top: 0;
        left: 0;

        width: 20px;
        height: 2px;
        background-color: black;
        transform: rotate(90deg);
        transition: all 0.4s ease-in-out;
      }
    }
    &:hover span,
    &:hover span::after {
      background-color: var(--color-red-dark);
    }
  }

  &__close-title {
    @include font(18, 25, 500);
  }

  &__content {
    position: relative;
    @include flex-container(column, flex-start);
  }

  &__item {
    cursor: pointer;
    padding: 8px;
    @include flex-container(row, flex-start, center);
    gap: 8px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #d1d1d1;
    }

    &:hover {
      & div {
        animation: hoverIcon 1.5s linear infinite;
      }

      & p {
        color: var(--color-primary-dark);
      }
    }
  }

  &__item-picture {
    position: relative;
    width: 40px;
    height: 40px;

    background-color: var(--color-primary-base);
    border-radius: 50%;

    @include flex-container(row, center, center);
  }

  &__item-text {
    color: black;
    @include font(16, 19, 500);
    letter-spacing: -0.32px;
    transition: all 0.4s ease-in-out;
  }
}

.widget-leave-active,
.widget-enter-active {
  transition: all 0.4s linear;
}

.widget-enter-from {
  opacity: 0;
  transform: rotate(0) scale(0.5);
}

.widget-enter-to {
  opacity: 1;
  transition: all 0.4s linear;
  transform: rotate(1440deg) scale(1);
}

.widget-leave-to {
  transition: all 0.4s linear;
  transform: rotate(1440deg) scale(0.5);
  opacity: 0;
}

.widget-open-leave-active,
.widget-open-enter-active {
  transition: all 0.6s linear 0.3s;
}

.widget-open-enter-from {
  opacity: 0;
  transform: translateY(-100%);
}

.widget-open-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.widget-open-leave-to {
  transition: all 0.4s linear;
  opacity: 0;
}

@keyframes firstStep {
  0% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(-120%);
    opacity: 0;
  }
  40% {
    opacity: 0;
    transform: translateX(100%);
  }
  50% {
    opacity: 1;
    transform: translateX(100%);
  }
  70% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes secondStep {
  0% {
    opacity: 1;
    transform: translateX(100%);
  }
  20% {
    opacity: 1;
    transform: translateX(0%);
  }
  50% {
    opacity: 1;
    transform: translateX(0%);
  }
  80% {
    opacity: 0;
    transform: translateX(-120%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes shadow {
  0% {
    box-shadow: 0 0 2px rgba(63, 2, 110);
  }

  50% {
    box-shadow:
      0 0 1px 8px rgba(63, 2, 110, 0.4),
      0 0 1px 18px rgba(63, 2, 110, 0.3),
      0 0 1px 36px rgba(63, 2, 110, 0.15);
    background-color: var(--color-primary-dark);
  }
  100% {
    box-shadow: 0 0 2px rgba(63, 2, 110);
    background-color: var(--color-primary-dark);
  }
}

@keyframes hoverIcon {
  0% {
    box-shadow: 0 0 2px rgba(63, 2, 110);
  }

  50% {
    box-shadow:
      0 0 1px 2px rgba(63, 2, 110, 0.4),
      0 0 1px 6px rgba(63, 2, 110, 0.3),
      0 0 1px 12px rgba(63, 2, 110, 0.15);
    background-color: var(--color-primary-dark);
  }
  100% {
    box-shadow: 0 0 2px rgba(63, 2, 110);
  }
}
</style>
