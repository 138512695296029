<template>
  <section ref="modal" class="catalog" :class="{ active: menuItems.active }">
    <div class="catalog__wrapper">
      <ul 
        ref="content" 
        class="catalog__main-list"
      >
        <li
          class="catalog__main-category"
          @mouseenter="false"
        >
          <MyLink
            class="catalog__item-wrapper"
            name="catalog"
            @click="inactiveCatalog"
          >
            <div class="catalog__image-w">
              <img
                class="catalog__image"
                src="@/assets/icons/grid-add.svg"
                :alt="_T('@Category catalog')"
              />
            </div>
            <span class="catalog__category-title">{{ _T("@Category catalog") }}</span>
          </MyLink>
        </li>
        <ModalCatalogItem
          v-for="category in filteredCatalog()"
          :key="category.id"
          :category="category"
          :active="activeCategory === category.id"
          @mouseenter="setActiveCategory(category.id)"
        />
      </ul>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useHeaderStore } from '~~/store/headerStore';
import { useCategoriesStore } from '~~/store/categoriesStore';
import ModalCatalogItem from '~~/modules/header/sections/ModalCatalogItem.vue';
import { addScopedEventListener } from '~~/utils/eventListener';
import { discontinuedCategoryCode } from '~/config/category.config';

const emits = defineEmits(['catalogModal', 'heightContent']);

const categoriesStore = useCategoriesStore();
const { getTopLevelCategories: categories } = storeToRefs(categoriesStore);

const header = useHeaderStore();
const menuItems = header.getModalCatalog;
const activeCatalog = header.activeCatalog;

const modal = ref(null);
const content = ref(null);
const activeCategory = ref(null);

function filteredCatalog() {
  return categories.value.filter(
    (category) => category.code !== discontinuedCategoryCode
  );
}

function inactiveCatalog() {
  activeCatalog(false);
  document.body.style.overflow = 'auto';
}

function resizeCatalog() {
  if (window.innerWidth <= 1024) {
    activeCatalog(false);
  }
}

function sendEmits() {
  emits('catalogModal', modal.value);
  emits('heightContent', content.value.scrollHeight);
}

function setActiveCategory(categoryId) {
  activeCategory.value = categoryId;
}

function initializeActiveCategory() {
  const categories = filteredCatalog();
  if (categories.length > 0) {
    setActiveCategory(categories[0].id);
  }
}

onMounted(() => {
  sendEmits();
  initializeActiveCategory();
  addScopedEventListener(window, 'resize', resizeCatalog);
});
</script>
