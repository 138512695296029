<template>
  <div class="search">
    <form class="search__form">
      <div class="search__image">
        <img src="~~/assets/icons/search.svg" alt="search" />
      </div>
      <input
        v-model="inputValue"
        type="text"
        class="search__input"
        :placeholder="_T('@Product search')"
      />
      <input
        type="submit"
        class="search__button"
        :value="_T('@Search')"
        @click.stop.prevent="onSearch"
      />
    </form>
  </div>
</template>

<script setup>
const inputValue = ref(null);

const onSearch = () => {
  if (0 !== inputValue.value.trim().length) {
    return navigateTo({
      name: getLocalizedRouteName("search"),
      query: { searchQuery: inputValue.value.trim() },
    });
  }
};
</script>

<style lang="scss" scoped>
.search {
  max-width: 590px;
  width: 100%;

  position: relative;

  @include bigMobile {
    max-width: 100%;
  }

  &__form {
    position: relative;

    @include flex-container(row, center, center);
  }

  &__image {
    position: absolute;
    left: 8px;

    font-size: 0;
  }

  &__input {
    width: 100%;

    @include font(16, 22);
    color: #8a8a8a;
    letter-spacing: 0.02em;

    background-color: white;

    border-radius: 30px;

    padding: 8px 80px 8px 40px;

    @include bigMobile {
      padding: 8px 8px 8px 40px;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;

      margin: 0;
    }

    &::placeholder {
      @include font(16, 22);
      color: black;

      @include bigMobile {
        @include font(16, 19);
      }
    }

    &:focus {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  &__button {
    height: 100%;
    width: max-content;

    position: absolute;
    right: 0;

    @include font(16, 19);
    color: white;

    background-color: var(--color-primary-base);
    border-radius: 30px;

    padding: 8px 16px;

    transition: background-color 0.1s ease-in-out;

    &:hover {
      background-color: var(--color-primary-light);
    }

    &:active {
      background-color: var(--color-primary-dark);
    }

    &:disabled {
      cursor: auto;

      background-color: var(--color-sky-darker);
    }

    @include bigMobile {
      height: 0;
      width: 0;

      opacity: 0;
      visibility: hidden;

      padding: 0;
    }
  }
}
</style>
