<template>
  <ul class="header-links">
    <li v-for="link in links" :key="link.title">
      <MyLink
        :name="link.name"
        :params="link.params"
        :hash="link.hash"
        class="header-links__item"
        :class="link.class"
      >
        {{ _T(link.title) }}
      </MyLink>
    </li>
  </ul>
</template>

<script setup>
const links = [
  {
    title: "@Blog",
    name: "blog",
  },
  {
    title: "@Contacts",
    name: "contacts",
  },
];
</script>
