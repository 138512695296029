export const promotionCategoryCode = "11417";
export const discontinuedCategoryCode = "11829";
export const paidDeliveryCategoryCodes = ["13089", "3282", "7125"];
export const recommendedCategoriesCodes = [
  "13116",
  "13130",
  "13124",
  "13177",
  "13239",
];
