<template>
  <BaseModal
    :title="accountModalComponent[currentComponent].title"
    :modal-width="accountModalComponent[currentComponent].width"
    :currentTab="currentComponent"
    @on-close-modal="onCloseModal"
  >
    <Component
      :is="accountModalComponent[currentComponent].component()"
      @change-component="onChangeComponent"
    />
  </BaseModal>
</template>

<script setup>
import {
  useModalStore,
  accountModal,
} from "~/modules/shared/modals/store/modalStore";
import BaseModal from "~/modules/shared/modals/BaseModal.vue";

const modalStore = useModalStore();
const { getExtra: extra } = storeToRefs(modalStore);

const currentComponent = ref(null);

currentComponent.value = extra.value(accountModal).component;

const accountModalComponent = {
  signIn: {
    component: () =>
      defineAsyncComponent(
        () => import("~/modules/account/components/sign/SignIn.vue"),
      ),
    title: _T("@Entrance"),
    width: 700,
  },
  signUp: {
    component: () =>
      defineAsyncComponent(
        () => import("~/modules/account/components/sign/SignUp.vue"),
      ),
    title: _T("@Registration"),
    width: 1044,
  },
  passwordReset: {
    component: () =>
      defineAsyncComponent(
        () => import("~/modules/account/components/sign/ResetPassword.vue"),
      ),
    title: _T("@Password reset"),
    width: 500,
  },
};

function onChangeComponent(component) {
  currentComponent.value = component;
}

const onCloseModal = () => {
  modalStore.toggleModal(accountModal);
};
</script>
