<template>
  <div ref="mainInfo" class="main-info">
    <div class="main-info__wrapper">
      <div class="main-info__content-w">
        <ButtonBurger />
        <div class="main-info__catalog-menu">
          <ButtonCatalog/>
          <RenderCacheable
            :max-age="86400"
            :cache-key="[getUserLanguage.name, getHash].join('--')"
          >
            <ModalCatalog
              class="main-info__catalog"
              :style="{
                '--bottom':
                  heightCatalog > bottomValue - heightHeader
                    ? -(bottomValue - heightHeader) + 'px'
                    : -heightCatalog + 'px',
              }"
              @catalog-modal="getModalCatalog"
              @height-content="getHeightContent"
            />
          </RenderCacheable>
        </div>
        <Logo
          class="main-info__logo"
          :full-logo="true"
          :big="true"
        />
      </div>

      <div class="main-info__content-w-center">
        <Search />

        <div class="main-info__content-w">
          <ButtonLanguage />
          <ButtonAccount />  
          <NavigationMenu />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ButtonBurger from "~~/modules/header/components/UI/ButtonBurger.vue";
import ButtonCatalog from "~~/modules/header/components/UI/ButtonCatalog.vue";
import Logo from "~~/modules/header/components/UI/Logo.vue";
import Search from "~/modules/header/components/UI/Search.vue";
import NavigationMenu from "~~/modules/header/sections/NavigationMenu.vue";
import ModalCatalog from "~~/modules/header/sections/ModalCatalog.vue";
import { addScopedEventListener } from "~~/utils/eventListener";
import ButtonLanguage from "~/modules/header/components/UI/ButtonLanguage.vue";
import { useLanguageFilterStore } from "~/store/languageFilterStore";
import { useCategoriesStore } from "~/store/categoriesStore";
import ButtonAccount from "~~/modules/header/components/UI/ButtonAccount.vue";

const props = defineProps({
  heightHeader: { type: Number, required: false },
});

const emits = defineEmits(["getHeightMainInfo"]);

const languageStore = useLanguageFilterStore();
const { getUserLanguage } = storeToRefs(languageStore);
const categoryStore = useCategoriesStore();
const { getHash } = storeToRefs(categoryStore);

const mainInfo = ref(null);
const catalogModal = ref(null);

const heightCatalog = ref(0);
const bottomValue = ref(0);

const hoverLogo = ref(false);

function getPosition() {
  emits("getHeightMainInfo", mainInfo.value.offsetHeight);
  getPositionButton();
}

function getModalCatalog(item) {
  catalogModal.value = item;
}

function getPositionButton() {
  bottomValue.value = document.documentElement.clientHeight;
}

function getHeightContent(value) {
  heightCatalog.value = value;
}

onMounted(() => {
  getPosition();
  addScopedEventListener(window, "resize", getPosition);
});
</script>

<style lang="scss" scoped>
.main-info {
  background-color: var(--color-grey-light-status);

  &__wrapper {
    @extend %width-main;

    @include flex-container(row, space-between, center);

    position: relative;

    margin: 0 auto;
    gap: 16px;
  }

  &__content-w,
  &__content-w-center {
    @include flex-container(row, space-between, center);
    gap: 24px;
  }

  &__content-w-center {
    max-width: 965px;
    width: 100%;
  }

  &__catalog-menu {
    @include bigMobile {
      display: none;
    }
  }

  &__catalog {
    --bottom: 0;

    max-width: 1440px;
    width: 100%;

    position: absolute;
    top: 44px;
    bottom: var(--bottom);
    left: 0;
    z-index: 501;

    border-radius: 0 0 16px 16px;
  }

  &__button-catalog,
  &__language {
    @include bigMobile {
      display: none;
    }
  }

  &__logo {
    width: 48px;
    height: 38px;
    @include flex-container(row, center, center);

    @include bigMobile {
      display: none;
    }
  }
}
</style>
