<template>
  <BaseModal :title="modalHeader.title" @on-close-modal="onCloseModal">
    <div class="modal-contacts">
      <p class="modal-contacts__text">
        {{ modalHeader.description }}
      </p>
      <form class="modal-contacts__form">
        <InputText
          v-model="form.name"
          :validate="v.name"
          :maxlength="255"
          :caption="_T('@Name')"
          autocomplete-type="given-name"
        />
        <InputPhone
          v-model="form.phone"
          :validate="v.phone"
          :caption="_T('@Phone number')"
        />
        <InputEmail v-model="form.email" :validate="v.email" caption="E-mail" />
        <BaseCheckbox
          v-model="form.isAgreementAccepted"
          :validate="v.isAgreementAccepted"
        >
          <span>
            {{ _T("@I agree with") }}
            <MyLink name="privacy-policy" target="_blank">
              {{ _T("@privacy policy") }}
            </MyLink>
          </span>
        </BaseCheckbox>
        <ButtonPrimary
          type="submit"
          :aria-label="_T('@Send')"
          button-width="363px"
          :disabled="sendForm.isHandled"
          @click.prevent="onSubmit"
        >
          {{ _T("@Send") }}
        </ButtonPrimary>
      </form>
    </div>
  </BaseModal>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import InputText from "~~/modules/shared/inputs/InputText.vue";
import InputPhone from "~~/modules/shared/inputs/InputPhone.vue";
import InputEmail from "~~/modules/shared/inputs/InputEmail.vue";
import {
  successfulModal,
  useModalStore,
  getContactModal,
} from "~/modules/shared/modals/store/modalStore";
import {
  emailValidate,
  maxLengthValidate,
  phoneValidate,
  regExpValidate,
  requiredValidate,
  sameAsValidate,
} from "~/utils/validators";
import { formatPhone } from "~~/utils/phoneHelper";
import { modalFormStore } from "~/modules/shared/modals/store/modalFormStore";
import { useUserStore } from "~/store/userStore";
import { useAPI } from "~/uses/useMyFetch";
import BaseModal from "~/modules/shared/modals/BaseModal.vue";
import ButtonPrimary from "~/modules/shared/buttons/ButtonPrimary.vue";
import { useSingletonHandler } from "~/uses/useSingletonHandler";
import BaseCheckbox from "~/modules/shared/inputs/BaseCheckbox.vue";

const modalStore = useModalStore();
const { getExtra } = storeToRefs(modalStore);

const formStore = modalFormStore();

const userStore = useUserStore();
const { currentUser: user } = storeToRefs(userStore);

onMounted(() => {
  if (null !== user.value) {
    form.name = user.value.name.firstName;
    form.phone = user.value.phone;
    form.email = user.value.email;
  }

  formStore.addForm({ v, requestOptions, id: "getContact" });
});

const modalHeader = getExtra.value(getContactModal);

const successfulData = {
  title: _T("@Successful modal title"),
  description: _T("@Successful modal text"),
  isWithTimeout: true,
};

const form = reactive({
  name: "",
  phone: "",
  email: "",
  isAgreementAccepted: true,
});

const rules = computed(() => ({
  name: {
    required: requiredValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 255),
    validateName: regExpValidate("@Must not contain spaces", /^\S*$/),
  },
  phone: {
    required: requiredValidate(),
    phone: phoneValidate(),
  },
  email: {
    required: requiredValidate(),
    email: emailValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 254),
  },
  isAgreementAccepted: {
    sameAs: sameAsValidate("@You must accept the terms and conditions", true),
  },
}));

const v = useVuelidate(rules, form);

const onSubmit = async () => {
  if (!formStore.isFormsReady()) {
    return;
  }

  return await sendForm.handle();
};

const onCloseModal = () => {
  modalStore.toggleModal(getContactModal);
};

const requestOptions = () => {
  return {
    event: "getContact",
    name: form.name,
    email: form.email,
    phone: formatPhone(form.phone),
    isConfirmed: true,
    form: {
      theme: modalHeader.title,
      url: useRuntimeConfig().public.appUrl + useRoute().fullPath,
    },
  };
};

const sendForm = useSingletonHandler(() =>
  useAPI("/marketing/event", {
    method: "POST",
    body: {
      ...requestOptions(),
    },
  }).then(() => {
    modalStore.toggleModal(getContactModal);

    modalStore.toggleModal(successfulModal, successfulData);
  }),
);

onUnmounted(() => {
  formStore.resetForms();
});
</script>

<style lang="scss" scoped>
.modal-contacts {
  @include flex-container(column, flex-start);
  gap: 24px;

  &__text {
    @include font;
    text-align: center;

    @include mobile {
      @include font(14, 18);
    }
  }

  &__form {
    width: 100%;
    @include flex-container(column, center, center);

    gap: 24px;
  }
}
</style>
