<template>
  <article 
    class="main-slider"
    :style="[
      { '--background-color': getBackgroundColor(items[currenIndex]) },
      { '--background-image': getBgImage(items[currenIndex]) },
    ]"
  >
    <div class="main-slider__wrp">
      <div class="main-slider__swiper">
        <Swiper 
          @slideChange="onSlideChange"
          :speed="1000"
          :slides-per-view="1" 
          :navigation="swiperNavigation"
          :loop="true"
          :autoplay="{
            delay: 3000,
            disableOnInteraction: false
          }"
          :pagination="{
            el: '.swiper-pagination',
            clickable: true,
          }"
          :modules="modules"
        >
          <SwiperSlide 
            v-for="(item, index) in filteredItems" 
            :key="index"
          >
            <BannerSlide :data="item" />
          </SwiperSlide>
        </Swiper>

        <PaginationSlider class="swiper-pagination"/>

        <template v-if="items.length > 1">
          <NavigationButton class="swiper-button-prev main-slider__btn-prev"/>
          <NavigationButton 
            class="swiper-button-next main-slider__btn-next" 
            :isNext="true"
          />
        </template>
      </div>        
    </div>
  </article>
</template>

<script setup>
import { useAsyncPrivateAPI } from "~/uses/useMyFetch";
import { useContentfulStore } from "~/store/contentfulStore";
import { useNormalizeCtfUrl } from "~/utils/normalize";
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import BannerSlide from "~/modules/shared/sliders/bannerSlider/ui/BannerSlide.vue";
import PaginationSlider from "~/modules/shared/sliders/bannerSlider/ui/PaginationSlider.vue";
import NavigationButton from "~/modules/shared/sliders/bannerSlider/ui/NavigationButton.vue";

const { data: items } = await useAsyncPrivateAPI("/entries", {
  params: {
    content_type: useRuntimeConfig().public.contentful.contentType.homeSlider,
  }, 
});

const currenIndex = ref(0);

const modules = [Navigation, Autoplay, Pagination];
const swiperNavigation = {
  nextEl: '.main-slider__btn-next',
  prevEl: '.main-slider__btn-prev',
};

const filteredItems = computed(() => {
  return items ? items.sort((a, b) => a.position.ru - b.position.ru) : [];
});

const onSlideChange = swiper => {
  currenIndex.value = swiper.realIndex;
};

function getBackgroundColor(item) {
  const DEFAULT_COLOR = "#1f1f1f";

  return item.colorBack ? item.colorBack.ru : DEFAULT_COLOR;
}

function getBgImage(item) {
  return `url('${useNormalizeCtfUrl(item?.backgroundImage.file.url)}')`;
}
</script>

<style lang="scss">
.pagination-slider {
  .swiper-pagination-bullet {
    background-color: white;

    opacity: 1;

    transition:
      width 0.3s ease-in-out,
      background-color 0.3s ease-in-out,
      border-radius 0.3s ease-in-out;

    &.swiper-pagination-bullet-active {
      width: 16px;

      background-color: var(--color-primary-base);

      border-radius: 10px;
    }
  }
}
</style>

<style lang="scss" scoped>
.main-slider {
  --background-color: var(--color-primary-light);
  --background-image: none;

  padding: 0 16px;

  &__wrp {
    max-width: 1440px;
    width: 100%; 

    margin: 0 auto;
  }

  &__swiper {
    position: relative;

    background-color: var(--background-color);
    background-image: var(--background-image);
    background-repeat: no-repeat;
    background-size: cover;

    border-radius: 40px;

    transition: background 0.3s ease-in-out;

    .swiper-pagination {
      bottom: 24px;

      @include bigMobile { 
        bottom: 16px;
      }
    }
  }
}
</style>