<template>
  <div class="home">
    <BannerSlider/>
    <CategoriesNav />
    <ProductShowcase :tabs="labelCarouselTabs" />
    <PromotionCarouselWrapper/>  
    <ProductShowcase :tabs="getRecommendedCategories(6)" with-scroll>
      <h5 class="home__subtitle">{{ _T("@Featured") }}</h5>
    </ProductShowcase>
    <div class="home__container">
      <InstallationSimilarPreview>
        <h4 class="news-and-article__title">{{ _T("@News & Articles") }}</h4>
        <p class="news-and-article__subtitle">{{ _T("@Everything you need to know about video surveillance from GreenVision") }}</p>
      </InstallationSimilarPreview>
    </div>
  </div>
</template>

<script setup>
import { useContentfulStore } from "~/store/contentfulStore";
import BannerSlider from "~/modules/shared/sliders/bannerSlider/BannerSlider.vue";
import ProductShowcase from "~/modules/shared/productShowcase/ProductShowcase.vue";
import PromotionCarouselWrapper from "~/modules/home/ui/components/PromotionCarouselWrapper.vue";
import InstallationSimilarPreview from "~/modules/installationWork/components/InstallationSimilarPreview.vue";
import CategoriesNav from "~/modules/home/ui/components/CategoriesNav.vue";
import { recommendedCategoriesCodes } from "~/config/category.config";
import { useCategoriesStore } from "~/store/categoriesStore";
import logoImg from "assets/icons/LogicPower-Logo.png";

const config = useRuntimeConfig();
const route = useRoute();

const categoriesStore = useCategoriesStore();
const { getCategoryByCode } = storeToRefs(categoriesStore);

const contentfulStore = useContentfulStore();
await contentfulStore.fetchHomeSlider();

const labelCarouselTabs = [
  {
    title: "@Novelties",
    component: defineAsyncComponent(
      () =>
        import(
          "~/modules/shared/sliders/productCarousels/ProductCarouselByLabel.vue"
        ),
    ),
    label: "novelty",
  },
  {
    title: "@Popular",
    component: defineAsyncComponent(
      () =>
        import(
          "~/modules/shared/sliders/productCarousels/ProductCarouselByLabel.vue"
        ),
    ),
    label: "bestSeller",
  },
];

const recommendedCategories = computed(() => {
  return recommendedCategoriesCodes.map((categoryCode) => {
    return getCategoryByCode.value(categoryCode);
  });
});

const getRecommendedCategories = (size = undefined) => {
  return recommendedCategories.value
    .slice(0, size ?? recommendedCategoriesCodes.length)
    .map((category) => {
      return {
        title: category.getName(),
        category,
        component: defineAsyncComponent(
          () =>
            import(
              "~/modules/home/ui/components/RecommendedCategoryProducts.vue"
            ),
        ),
      };
    });
};

useSeoMeta({
  title: _T("@Meta title"),
  description: _T("@Meta description"),
  ogUrl: config.public.appUrl + route.path,
  ogImage: logoImg,
});
</script>

<style lang="scss">
.home {
  @include flex-container(column, flex-start);
  gap: 32px;

  padding-block: 40px;

  &__container {
    @include flex-container(row, center, center);
    padding-inline: 16px;
  }

  &__subtitle {
    @include font(26, 32, 600);
    align-self: flex-start;

    @include mobile {
      @include font(18, 24, 600);
    }

    &.colored {
      color: var(--color-primary-base);
    }
  }

  .news-and-article {
    &__title {
      @include font(28, 38, 600);
      margin-bottom: 8px;
    }

    &__subtitle {
      @include font(20, 30, 500);
      text-transform: uppercase;
    }
  }
}
</style>

<style scoped lang="scss">
.button-primary {
  width: min(265px, 100%);
  align-self: center;
}
</style>
