<template>
  <div
    class="modal-menu"
    :class="[{ active: menuItems.active }, { mobileCatalog }]"
    @click.stop="closeModal"
  >
    <div class="modal-menu__wrapper" @click.stop>
      <section class="modal-menu__content">
        <header class="modal-menu__header">
          <div class="modal-menu__header-block">
            <Logo :full-logo="true" :big="true" @click.stop="closeModal" />
            <ButtonLanguage :modal-menu="true" />
            <button class="modal-menu__close" @click="closeModal">
              <img src="~~/assets/icons/cross-black.svg" alt="close button" />
            </button>
          </div>
          <div class="modal-menu__header-block">
            <div
              v-if="null === userStore.currentUser"
              class="modal-menu__account"
            >
              <img
                src="~~/assets/icons/account-header.svg"
                alt="account"
                class="modal-menu__account-img"
              />
              <div class="modal-menu__account-buttons">
                <div class="modal-menu__account-btn" @click="signIn">
                  {{ _T("@Log in") }}
                </div>
                <div class="modal-menu__account-buttons-line"></div>
                <div class="modal-menu__account-btn" @click="signUp">
                  {{ _T("@Registration") }}
                </div>
              </div>
            </div>
            <MyLink
              v-else
              name="account_profile"
              class="modal-menu__account"
              @click.stop="closeModal"
            >
              <div class="modal-menu__account-img-w">
                <img
                  src="~~/assets/icons/account-logo.svg"
                  alt="account logo"
                />
              </div>
              <p class="modal-menu__account-title">
                {{ userStore.userFullName }}
              </p>
            </MyLink>
          </div>
        </header>
        <main class="modal-menu__main">
          <div class="modal-menu__main-wrapper">
            <section class="modal-menu__section">
              <ModalMenuItem
                class="modal-menu__item mobileCatalog"
                :is-button="true"
                @click="openCatalog"
              >
                <template #title>
                  <span class="modal-menu__item-title">{{
                    _T("@Goods catalog")
                  }}</span>
                </template>
                <template #image>
                  <img
                    src="~~/assets/icons/catalog-black.svg"
                    :alt="_T('@Goods catalog')"
                  />
                </template>
              </ModalMenuItem>
            </section>

            <section class="modal-menu__section">
              <ModalMenuItem
                class="modal-menu__item"
                :is-button="true"
                @click="showCart"
              >
                <template #title>
                  <span class="modal-menu__item-title"
                    >{{ `${_T("@Cart")}` }}
                  </span>
                </template>
                <template #image>
                  <div class="cart-img">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.5 22C20.3284 22 21 21.3284 21 20.5C21 19.6716 20.3284 19 19.5 19C18.6716 19 18 19.6716 18 20.5C18 21.3284 18.6716 22 19.5 22Z"
                        fill="#2B2B2B"
                        stroke="#2B2B2B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.5 22C10.3284 22 11 21.3284 11 20.5C11 19.6716 10.3284 19 9.5 19C8.67157 19 8 19.6716 8 20.5C8 21.3284 8.67157 22 9.5 22Z"
                        fill="#2B2B2B"
                        stroke="#2B2B2B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 4H22L20 15H7L5 4ZM5 4C4.83333 3.33333 4 2 2 2"
                        stroke="#2B2B2B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20 15H7H5.23077C3.44646 15 2.5 15.7812 2.5 17C2.5 18.2188 3.44646 19 5.23077 19H19.5"
                        stroke="#2B2B2B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div v-if="cartQuantity > 0" class="cart-number">
                      <span class="cart-count">{{ cartQuantity }}</span>
                    </div>
                  </div>
                </template>
              </ModalMenuItem>

              <ModalMenuItem
                v-if="null !== userStore.currentUser"
                class="modal-menu__item"
                :route-name="'account_favorites'"
                @click="closeModal"
              >
                <template #title>
                  <span class="modal-menu__item-title"
                    >{{ `${_T("@Favorites")}` }}
                  </span>
                </template>
                <template #image>
                  <div class="cart-img">
                    <img
                      src="/img/account-favorite.svg"
                      alt="favorites button" 
                    />
                    <div v-if="favoritesProducts > 0" class="cart-number">
                      <span class="cart-count">{{ favoritesProducts }}</span>
                    </div>
                  </div>
                </template>
              </ModalMenuItem>

              <ModalMenuItem
                v-else
                class="modal-menu__item"
                :is-button="true"
                @click="signIn"
              >
                <template #title>
                  <span class="modal-menu__item-title"
                    >{{ `${_T("@Favorites")}` }}
                  </span>
                </template>
                <template #image>
                  <div class="cart-img">
                    <img
                      src="/img/account-favorite.svg"
                      alt="favorites button"
                    />
                  </div>
                </template>
              </ModalMenuItem>

              <ModalMenuItem
                class="modal-menu__item"
                :route-name="'comparison'"
                @click="closeModal"
              >
                <template #title>
                  <span class="modal-menu__item-title"
                    >{{ `${_T("@Comparison")}` }}
                  </span>
                </template>
                <template #image>
                  <div class="cart-img">
                    <img
                      src="~~/assets/icons/comparison-mobile-menu.svg"
                      alt="comparison button"
                    />
                    <div
                      v-if="comparisonProducts.length > 0"
                      class="cart-number"
                    >
                      <span class="cart-count">{{
                        comparisonProducts.length
                      }}</span>
                    </div>
                  </div>
                </template>
              </ModalMenuItem>
            </section>

            <section class="modal-menu__section">
              <ModalMenuItem
                :href="`tel:${_T('@Call center number')}`"
                class="modal-menu__item"
                @click="closeModal()"
              >
                <template #title>
                  <span class="modal-menu__item-title">
                    {{ _T("@Sales department") }}:
                  </span>
                  <span class="modal-menu__item-title">
                    {{ _T("@Call center number") }}
                  </span>
                </template>
                <template #image>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.1181 14.702L13.9998 15.5C11.2181 14.1038 9.49985 12.5 8.49985 10L9.2698 5.8699L7.81436 2L4.06344 2C2.9359 2 2.04799 2.93178 2.21639 4.04668C2.63679 6.83 3.87638 11.8765 7.49985 15.5C11.305 19.3052 16.7856 20.9564 19.8019 21.6127C20.9666 21.8662 21.9998 20.9575 21.9998 19.7655L21.9998 16.1812L18.1181 14.702Z"
                      stroke="rgb(43 43 43)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </template>
              </ModalMenuItem>
            </section>

            <section class="modal-menu__section">
              <ModalMenuItem
                :route-name="'contacts'"
                class="modal-menu__item"
                @click="closeModal()"
              >
                <template #title>
                  <span class="modal-menu__item-title">
                    {{ _T("@Contacts") }}
                  </span>
                </template>
                <template #image>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22 6.6C22 9.69279 16.5 15 16.5 15C16.5 15 11 9.69279 11 6.6C11 3.50721 13.4624 1 16.5 1C19.5376 1 22 3.50721 22 6.6Z"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                    />
                    <path
                      d="M16.5 7C16.7761 7 17 6.77614 17 6.5C17 6.22386 16.7761 6 16.5 6C16.2239 6 16 6.22386 16 6.5C16 6.77614 16.2239 7 16.5 7Z"
                      fill="#2B2B2B"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.0881 17.5265L10.9988 18.125C8.91207 17.0779 7.6231 15.875 6.87294 14L7.45053 10.9024L6.35873 8L4.05955 8C2.932 8 2.04472 8.93144 2.24287 10.0414C2.62713 12.1941 3.61211 15.6148 6.12279 18.125C8.76171 20.7634 12.4747 22.0211 14.8083 22.5849C15.9669 22.8649 17 21.9575 17 20.7655L17 18.6359L14.0881 17.5265Z"
                      fill="white"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </template>
              </ModalMenuItem>
            </section>

            <section class="modal-menu__section">
              <ModalMenuItem
                :route-name="'blog'"
                class="modal-menu__item"
                @click="closeModal()"
              >
                <template #title>
                  <span class="modal-menu__item-title">{{ _T("@Blog") }}</span>
                </template>
                <template #image>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 8L4 6L16 6V8"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 6L10 18M10 18H12M10 18H8"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 13.5L14 12L20 12V13.5"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17 12V18M17 18H15.5M17 18H18.5"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </template>
              </ModalMenuItem>
            </section>
          </div>
        </main>
        <main class="modal-menu__catalog">
          <div class="modal-menu__catalog-wrapper">
            <ButtonBack @click="onCollapseCategories" />
            <ClientOnly>
              <ul class="modal-menu__catalog-menu">
                <li 
                  class="modal-menu__main-category"
                  v-if="isTopLevelCategories"
                  @click="closeModal"
                >
                  <MyLink
                    class="modal-menu__item-wrapper"
                    name="catalog"
                  >
                    <div class="modal-menu__image-w">
                      <img
                        class="modal-menu__image"
                        src="@/assets/icons/grid-add-catalog.svg"
                        :alt="_T('@Category catalog')"
                      />
                    </div>
                    <span class="modal-menu__category-title">Вся продукция</span>
                  </MyLink>
                </li>
                <li 
                  class="modal-menu__main-category"
                  v-if="!isTopLevelCategories"
                  @click="closeModal"
                >
                  <MyLink
                    class="modal-menu__item-wrapper"
                    name="category"
                    :params="{ categorySlug: currentTopCategory }"
                  >
                    <span class="modal-menu__category-title">Все товары</span>
                  </MyLink>
                </li>
                <MenuCatalogItem
                  v-for="category in categories"
                  :key="category.id"
                  :category="category"
                  @click="onExpandCategory(category)"
                />
              </ul>
            </ClientOnly>
          </div>
        </main>
        <footer class="modal-menu__footer">
          <p class="modal-menu__social-network-title">
            {{ _T("@We on social media") }}:
          </p>
          <div class="modal-menu__social-networks">
            <a
              v-for="social in socials"
              :href="social.link"
              class="modal-menu__social-item" 
              target="_blank" 
            >
              <img :src="social.iconViolet" :alt="social.alt" />
            </a>
          </div>
        </footer>
      </section>
    </div>
  </div>
</template>

<script setup>
import MenuCatalogItem from "~~/modules/header/sections/MenuCatalogItem.vue";
import ButtonLanguage from "~~/modules/header/components/UI/ButtonLanguage.vue";
import ModalMenuItem from "~~/modules/header/components/UI/ModalMenuItem.vue";
import ButtonBack from "~~/modules/header/components/UI/ButtonBack.vue";
import Logo from "~~/modules/header/components/UI/Logo.vue";
import { useHeaderStore } from "~~/store/headerStore";
import { useCategoriesStore } from "~~/store/categoriesStore";
import socials from "~/config/social.config";
import { addScopedEventListener } from "~~/utils/eventListener";
import { useCartStore } from "~~/store/cartStore";
import { useComparisonStore } from "~/store/comparisonStore";
import { useUserStore } from "~/store/userStore";
import {
  accountModal,
  cartModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import { useFavoriteProductStore } from "~/store/favoriteProductStore";
import {
  discontinuedCategoryCode, 
  promotionCategoryCode,
} from "~/config/category.config";
import { isPromotionEnded } from "~/utils/valueMatched";
import _ from 'lodash';

const categoriesStore = useCategoriesStore();
const {
  getCategoriesByParent: parentCategories,
  getChildren: children,
  getTopLevelCategories: topLevelCategories,
} = storeToRefs(categoriesStore);

const cartStore = useCartStore();
const { getProductsQuantity: cartQuantity } = storeToRefs(cartStore);

const comparisonStore = useComparisonStore();
const { getProducts: comparisonProducts } = storeToRefs(comparisonStore);

const favoriteProductStore = useFavoriteProductStore();
const { getProductsLength: favoritesProducts } =
  storeToRefs(favoriteProductStore);

const userStore = useUserStore();
const modalStore = useModalStore();

const mobileCatalog = ref(false);

const header = useHeaderStore();
const menuItems = header.getModalMenu;

const activeMenu = header.activeModal;
const activeCatalog = header.activeCatalog;

const categories = ref(null);
const isTopLevelCategories = ref(true);
const currentTopCategory = ref("/");

/*const aboutCompanyList = ref([
  { title: "@Delivery and payment", routeName: "delivery" },
  { title: "@Cooperation", routeName: "cooperation" },
]);*/

function closeModal(type) {
  if (type !== "dropdown") {
    header.activeModal(false);
    mobileCatalog.value = false;
  } else {
    header.activeModal(true);
  }
}

function showCart() {
  modalStore.toggleModal(cartModal);
  closeModal();
}

function openCatalog() {
  if (window.innerWidth > 1024) {
    activeMenu(false);
    activeCatalog(true);
    document.body.style.overflow = "hidden";
  } else {
    mobileCatalog.value = true;
  }
}

function defaultModal() {
  mobileCatalog.value = window.innerWidth <= 1024;
}

function onExpandCategory(category) {
  if(!category.parent) {
    currentTopCategory.value = category?.slug;
  }

  if (0 === children.value(category).length) {
    mobileCatalog.value = false;

    return (categories.value = topLevelCategories.value.filter(
      (category) => category.code !== discontinuedCategoryCode,
    ));
  }

  if (promotionCategoryCode !== category.code) {
    return (categories.value = children.value(category));
  }

  return (categories.value = children
    .value(category)
    .filter((child) => !isPromotionEnded(child))
    .sort((a, b) => b.promotion.period.endAt - a.promotion.period.endAt));
}

function onCollapseCategories() {
  if (null === categories.value[0].parent) {
    mobileCatalog.value = false;
  }

  categories.value = parentCategories
    .value(categories.value[0].parent)
    .filter((category) => category.code !== discontinuedCategoryCode);
}

function signIn() {
  modalStore.toggleModal(accountModal, { component: "signIn" });
  closeModal();
}

function signUp() {
  modalStore.toggleModal(accountModal, { component: "signUp" });
  closeModal();
}

watch(categories, () => {
  const isTopCategory = _.isEqual(topLevelCategories.value, categories.value);

  if(isTopCategory) {
    currentTopCategory.value = "";
  }
  
  isTopLevelCategories.value = isTopCategory;
});

onMounted(() => {
  categories.value = topLevelCategories.value.filter(
    (category) => category.code !== discontinuedCategoryCode,
  );
  addScopedEventListener(window, "resize", defaultModal);
});
</script>
