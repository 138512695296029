<template>
  <BaseModal
    :modal-width="955"
    background-color="#222429"
    title-color="white"
    close-button-color="white"
    without-header-border
    @on-close-modal="onCloseModal"
  >
    <div class="autonomic-subscribe">
      <div class="autonomic-subscribe__content">
        <span class="autonomic-subscribe__line"></span>
        <h3 class="autonomic-subscribe__title">
          {{ _T(autonomicData.title) }}
        </h3>

        <div class="autonomic-subscribe__container">
          <p
            v-for="(description, index) in autonomicData.description"
            :key="index"
            class="autonomic-subscribe__description"
          >
            {{ _T(description) }}
          </p>
        </div>
      </div>
      <form action="" class="autonomic-subscribe__form">
        <div class="autonomic-subscribe__inputs">
          <InputText
            v-model="form.firstName"
            :validate="v.firstName"
            :maxlength="255"
            :caption="_T('@Name')"
            autocomplete-type="given-name"
          />
          <InputPhone
            v-model="form.phone"
            :validate="v.phone"
            :caption="_T('@Phone number')"
          />
          <InputEmail
            v-model="form.email"
            :validate="v.email"
            caption="E-mail"
          />
        </div>

        <div class="autonomic-subscribe__checkbox-wrp">
          <BaseCheckbox
            v-model="form.isAgreementAccepted"
            :validate="v.isAgreementAccepted"
          >
            <span>
              {{ _T("@I agree with") }}
              <MyLink
                class="autonomic-subscribe__caption-link"
                :name="'privacy-policy'"
              >
                {{ _T("@privacy policy") }}
              </MyLink>
            </span>
          </BaseCheckbox>
          <ButtonPrimary
            type="button"
            :aria-label="_T('@Send')"
            :disabled="subscribeHandler.isHandled"
            @click.prevent="onSubscribe"
          >
            <span>{{ _T("@Send") }}</span>
            <img src="/img/arrow_right_light.svg" alt="arrow" />
          </ButtonPrimary>
        </div>
      </form>
    </div>
  </BaseModal>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import InputText from "~~/modules/shared/inputs/InputText.vue";
import InputEmail from "~/modules/shared/inputs/InputEmail.vue";
import InputPhone from "~/modules/shared/inputs/InputPhone.vue";
import {
  autonomicSubscriptionModal,
  successfulModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import {
  emailValidate,
  maxLengthValidate,
  requiredValidate,
  sameAsValidate,
  phoneValidate,
} from "~/utils/validators";
import { modalFormStore } from "~/modules/shared/modals/store/modalFormStore";
import { useUserStore } from "~/store/userStore";
import { useSingletonHandler } from "~/uses/useSingletonHandler";
import { useAPI } from "~/uses/useMyFetch";
import BaseModal from "~/modules/shared/modals/BaseModal.vue";
import BaseCheckbox from "~/modules/shared/inputs/BaseCheckbox.vue";
import ButtonPrimary from "~/modules/shared/buttons/ButtonPrimary.vue";

const modalStore = useModalStore();
const formStore = modalFormStore();
const userStore = useUserStore();

const { currentUser: user } = storeToRefs(userStore);

const successfulData = {
  title: _T("@Successful modal title"),
  description: _T("@Successful modal text"),
  isWithTimeout: true,
};

const autonomicData = {
  title: "@Want to know more about Autonomic's capabilities",
  description: [
    "@Get a free consultation",
    "@Our manager will contact you and answer all questions.",
  ],
};

const form = reactive({
  firstName: "",
  phone: "",
  email: "",
  isAgreementAccepted: true,
});

const rules = computed(() => ({
  firstName: {
    required: requiredValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 255),
  },
  phone: {
    required: requiredValidate(),
    phone: phoneValidate(),
  },
  email: {
    required: requiredValidate(),
    email: emailValidate(),
  },
  isAgreementAccepted: {
    sameAs: sameAsValidate("@You must accept the terms and conditions", true),
  },
}));

const v = useVuelidate(rules, form);

const subscribeHandler = useSingletonHandler(() =>
  useAPI("/marketing/event", {
    method: "POST",
    body: {
      event: "autonomicLandingRequestCreated",
      email: form.email,
      isConfirmed: true,
      name: form.firstName,
      phone: form.phone,
    },
  }).then(() => {
    modalStore.toggleModal(autonomicSubscriptionModal);

    modalStore.toggleModal(successfulModal, successfulData);
  }),
);

async function onSubscribe() {
  if (formStore.isFormsReady()) {
    await subscribeHandler.handle();
  }
}

const onCloseModal = () => {
  modalStore.toggleModal(autonomicSubscriptionModal);
};

onMounted(() => {
  if (null !== user.value) {
    form.firstName = user.value.name.firstName;
    form.email = user.value.email;
  }

  formStore.addForm({ v, id: "subscription" });
});

onUnmounted(() => {
  formStore.resetForms();
});
</script>

<style lang="scss" scoped>
.autonomic-subscribe {
  @include flex-container(row, space-between, center);
  gap: 57px;

  @include mobile {
    @include flex-container(column, center, center);
  }

  &__content {
    max-width: 390px;
    width: 100%;

    @include flex-container(column);
    gap: 16px;

    @include mobile {
      align-items: center;
      text-align: center;
    }
  }

  &__title {
    font-family: "JWH V1", sans-serif;
    @include font(20, 24, 400);
    letter-spacing: 0.2px;

    @include mobile {
      @include font(18, 22, 400);
      letter-spacing: 0.18px;
    }
  }

  &__description {
    max-width: 360px;
    @include font(16, 23, 400);
    letter-spacing: 0.32px;
    color: #e9e9e9;

    @include mobile {
      @include font(14, 20, 400);
      letter-spacing: 0.28px;
    }
  }

  &__line {
    width: 80px;

    border: 1px solid var(--color-primary-base);

    @include bigMobile {
      width: 60px;
    }
  }

  &__form {
    width: 100%;
    @include flex-container(column, space-between);

    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.5);

    background: linear-gradient(
      117deg,
      rgba(243, 243, 243, 0.3) 24.93%,
      rgba(243, 243, 243, 0.2) 101.84%
    );
    box-shadow: 0 1px 25px 0 rgba(0, 0, 0, 0.05);

    gap: 24px;
    padding: 16px 24px;

    @include mobile {
      max-width: max-content;

      padding: 16px;
    }
  }

  &__inputs {
    @include flex-container(column, space-between, center);

    gap: 24px;
  }

  &__checkbox-wrp {
    width: min(100%, 363px);
    align-self: center;

    @include flex-container(column, center, center);
    gap: 24px;
  }
}
</style>
