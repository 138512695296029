<template>
  <span v-if="getIsPoliciesFetching" class="ui-loader" />
  <div
    v-else-if="isCreditAvailable(product.getPrice('current'))"
    class="summary-credit"
  >
    <div class="summary-credit__w">
      <ul class="summary-credit__list">
        <template v-for="payment in paymentTypes(product.getPrice('current'))">
          <li
            v-if="payment.isAvailable"
            class="summary-credit__item"
            :title="payment.view.title"
          >
            <div class="summary-credit__item-icon">
              <img
                :src="payment.view.icon"
                :alt="payment.view.title"
                loading="lazy"
              />
              <span>{{ payment.view.options().months }}</span>
            </div>
            <div>
              <p class="summary-credit__item-name">
                {{ _T(payment.view.title) }}
              </p>
              <p class="summary-credit__item-number">
                {{
                  cleanStringsConcat([
                    _T("@From date"),
                    priceFormatter(payment.view.options().amount),
                    _T("@Product one month pay"),
                  ])
                }}
              </p>
            </div>
          </li>
        </template>
      </ul>
    </div>
    <ButtonSecondary button-width="fit-content" @click="onBuyInCredit">
      <span>{{ _T("@Product buy in credit") }}</span>
    </ButtonSecondary>
  </div>
</template>

<script setup>
import ButtonSecondary from "~/modules/shared/buttons/ButtonSecondary.vue";
import { useCartStore } from "~/store/cartStore";
import {
  cartModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import { usePaymentPolicyStore } from "~/store/paymentPolicyStore";
import { Product } from "~/models/product.model";
import { priceFormatter } from "~/utils/formatters.utils";

const props = defineProps({
  product: { type: Product, required: true },
});

const cartStore = useCartStore();
const modalStore = useModalStore();
const paymentPolicyStore = usePaymentPolicyStore();

const { hasProduct } = storeToRefs(cartStore);
const { getPrivatBankPayment, getIsPoliciesFetching } =
  storeToRefs(paymentPolicyStore);

useAsyncData("paymentPolicyStore", () =>
  paymentPolicyStore.fetch().then(() => true),
);

const paymentTypes = (price) => [
  {
    isAvailable: getPrivatBankPayment.value("payParts", price).isAvailable,
    view: {
      title: "@Product payment in installments",
      icon: "/img/pay-parts.svg",
      options: () => getPaymentOptions("payParts", price),
    },
  },
  {
    isAvailable: getPrivatBankPayment.value("instantInstallment", price)
      .isAvailable,
    view: {
      title: "@Product instant installments",
      icon: "/img/instant-installment.svg",
      options: () => getPaymentOptions("instantInstallment", price),
    },
  },
];

const isCreditAvailable = (price) => {
  return paymentTypes(price).some((payment) => payment.isAvailable);
};

const getPaymentOptions = (paymentMethod, price) => {
  const months = getPrivatBankPayment.value(paymentMethod, price).partsCount
    .max;
  const amount = Math.ceil(price / months);

  return reactive({
    amount,
    months,
  });
};

const onBuyInCredit = async () => {
  if (!hasProduct.value(props.product)) {
    await cartStore.addItem(props.product);
  }

  modalStore.toggleModal(cartModal);
};
</script>

<style scoped lang="scss">
.summary-credit {
  @include flex-container(row, flex-start, center);
  flex-wrap: wrap;
  gap: 16px;

  background-color: var(--color-sky-lightest);
  border-radius: 16px;

  padding: 24px 16px;

  @include mobile {
    @include flex-container(column, center, flex-start);
    padding: 8px 16px;
  }

  &__w {
    @media (max-width: 450px) {
      position: relative;
      width: 100%;

      &::after {
        content: "";
        position: absolute;
        z-index: 20;
        top: 0;
        width: 52px;
        height: 36px;

        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 20%,
          rgba(255, 255, 255, 0)
        );
      }

      &::after {
        content: "";
        right: 0;
        transform: rotate(180deg);
      }
    }
  }

  &__list {
    width: 100%;
    @include flex-container(row, flex-start, center);
    flex-wrap: wrap;
    gap: 8px;

    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }

    @include mobile {
      flex-wrap: nowrap;
    }
  }

  &__item {
    @include flex-container(row, center, center);
    gap: 8px;

    border-radius: 20px;
    background-color: white;

    padding: 4px 8px;

    @include mobile {
      white-space: nowrap;
    }
  }

  &__item-icon {
    position: relative;

    width: 28px;
    height: 28px;

    & span {
      position: absolute;
      z-index: 10;
      bottom: 0;
      right: 0;

      width: 12px;
      height: 12px;

      @include font(7, 12, 600);
      color: white;
      text-align: center;

      background-color: var(--color-primary-base);
      border-radius: 50%;
    }
  }

  &__item-name {
    color: var(--color-primary-base);
    @include font(10, 14, 500);
  }

  &__item-number {
    @include font(10, 14, 500);
  }
}
</style>
