<template>
  <div class="cart-delivery">
    <div class="cart-delivery__block">
      <p v-if="!isFreeDelivery" class="cart-delivery__caption">
        {{ _T("@Cart free delivery condition") }}
        {{ getRequiredAmount }}&#8372;
      </p>
      <p v-else class="cart-delivery__caption">
        {{ _T("@Cart free delivery achieved") }}
      </p>
      <img
        class="cart-delivery__image"
        src="~/assets/images/cart-free-delivery-achieved.png"
        alt="free delivery success"
      />
      <div class="cart-delivery__progress">
        <img
          v-if="!isFreeDelivery"
          class="cart-delivery__progress-image"
          src="~/assets/images/cart-free-delivery-progress.png"
          alt="free delivery progress"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCartStore } from "~/store/cartStore";

const cartStore = useCartStore();
const { getTotalAmount: amount } = storeToRefs(cartStore);

const isFreeDelivery = computed(() => {
  return amount.value >= FREE_DELIVERY_AMOUNT;
});

const getRequiredAmount = computed(() => {
  return FREE_DELIVERY_AMOUNT - amount.value;
});

const getDeliveryBarProgress = computed(() => {
  if (isFreeDelivery.value) {
    return 100;
  }

  return (amount.value / FREE_DELIVERY_AMOUNT) * 100;
});
</script>

<style scoped lang="scss">
.cart-delivery {
  width: 100%;

  @include flex-container(row, center, center);

  padding: 24px 16px;
  margin-top: auto;

  &__block {
    width: min(533px, 100%);

    @include flex-container(column, center, center);
    gap: 8px;

    background-color: #03b42a;
    border-radius: 16px;

    padding: 8px 16px;

    @include bigMobile {
      padding: 8px;
    }
  }

  &__caption {
    font-weight: 500;
    text-align: center;
    color: white;
  }

  &__image {
    align-self: flex-end;
  }

  &__progress {
    position: relative;

    width: 100%;
    height: 8px;

    background-color: white;
    border-radius: 30px;

    &:after {
      content: "";
      display: block;

      width: calc(v-bind("getDeliveryBarProgress") * 1%);
      height: 8px;

      background-color: var(--color-primary-base);
      border-radius: inherit;

      transition: width 0.2s;
    }
  }

  &__progress-image {
    position: absolute;
    bottom: 16px;
    left: calc(v-bind("getDeliveryBarProgress") * 1% - 24px);

    transition: left 0.2s;
  }
}
</style>
